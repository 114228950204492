<script>
import View from 'ol/View'
import Map from 'ol/Map'
import TileLayer from 'ol/layer/Tile'
import {XYZ} from "ol/source";
import {getPointResolution, Projection, useGeographic} from "ol/proj";
import {loadOrchardMap} from "@/util/api";

import 'ol/ol.css'
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {all} from "ol/loadingstrategy";
import Geolocation from 'ol/Geolocation';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import {default as OSMXML} from "ol/format/OSMXML";
import {Fill, Icon, Stroke, Style, Text, Circle as CircleStyle} from "ol/style";
import {Select} from "ol/interaction";
import {never} from "ol/events/condition";
import {Control, ScaleLine, defaults as controlDefaults} from "ol/control";
import mapApi from '@/util/map-api.json'
import {Overlay} from "ol";

class LocateControl extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(opt_options) {
    const options = opt_options || {};

    const button = document.createElement('button');
    button.innerHTML = '<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'29\' height=\'29\' viewBox=\'0 0 20 20\'>\n' +
      '  <path\n' +
      '    d=\'M10 4C9 4 9 5 9 5v.1A5 5 0 0 0 5.1 9H5s-1 0-1 1 1 1 1 1h.1A5 5 0 0 0 9 14.9v.1s0 1 1 1 1-1 1-1v-.1a5 5 0 0 0 3.9-3.9h.1s1 0 1-1-1-1-1-1h-.1A5 5 0 0 0 11 5.1V5s0-1-1-1m0 2.5a3.5 3.5 0 1 1 0 7 3.5 3.5 0 1 1 0-7\'/>\n' +
      '  <circle cx=\'10\' cy=\'10\' r=\'2\'/>\n' +
      '</svg>\n';

    const element = document.createElement('div');
    element.className = 'locate-me ol-unselectable ol-control';
    element.appendChild(button);
    super({
      element: element,
      target: options.target,
    });
    // TODO reflect tracking status visually
    this.geolocation = opt_options['geolocation'];
    this.geolocation.on('change:tracking', this.trackingChanged.bind(this), false)
    button.addEventListener('click', this.handleLocate.bind(this), false);
    console.log("Created LocateControl with " + element)
  }

  trackingChanged() {
    console.log("Tracking: " + this.geolocation.getTracking())
    this.element.classList.toggle('tracking', this.geolocation.getTracking());
  }

  handleLocate() {
    let tracking = !this.geolocation.getTracking()
    console.log("LocateControl clicked " + tracking)
    this.geolocation.setTracking(tracking);
  }
}

export default {
  name: 'OrchardMap',
  components: {},
  data() {
    return {
      map: null,
      orchardSource: null,
      overviewSource: null,
      basemapLayers: [],
      blocks: {},
      rows: {}
    }
  },
  props: {
    companies: {
      type: Array,
      default() { return []}
    },
    company: {
      default: null
    },
    kpin: {
      default: null
    },
    active_block: {
      type: String,
      default: null
    },
    active_row: {
      type: String,
      default: null
    },
    bayColor: {
      type: Function,
      default: (_) => "#ff000077"
    },
    hovered: {
      type: Array,
      default() {
        return []
      }
    },
    selected: {
      type: Array,
      default() {
        return []
      }
    },
    geolocation: {
      type: Object,
      default: null
    },
    autoCenter: {
      type: Boolean,
      default: false
    },
    autoCenterMode: {
      type: String,
      default: 'point'
    }
  },
  watch: {
    companies(_) {
      this.overviewSource.refresh()
    },
    kpin(_) {
      this.orchardSource.refresh()
    },
    autoCenter(new_value, old_value) {
      console.log("AutoCenter changed", old_value, new_value)
      if (!old_value && new_value) {
        this.autoCenterMap(this.geolocation)
      }
    },
    autoCenterMode(new_value, old_value) {
      if (new_value !== old_value) {
        this.autoCenterMap(this.geolocation)
      }
    },
    active_row(_) {
      this.autoCenterMap(this.geolocation)
    },
    rows(_) {
      this.autoCenterMap(this.geolocation)
    },
    active_block(_) {
      this.autoCenterMap(this.geolocation)
    },
    blocks(_) {
      this.autoCenterMap(this.geolocation)
    },
    geolocation(loc) {
      if (loc) {
        const heading = loc.heading
        if (heading === null || heading === undefined) {
          this.$refs.geolocation_marker.src = '/img/icons/geolocation_marker.png'
          this.$refs.geolocation_marker.style.rotate = undefined
        } else {
          this.$refs.geolocation_marker.style.rotate = `${heading}deg`
          this.$refs.geolocation_marker.src = '/img/icons/geolocation_marker_heading.png'
        }
        if (this.autoCenter && this.autoCenterMode === 'point') {
          this.autoCenterMap(loc)
        }

        this.marker.setPosition(loc.position);
        // TODO change image if heading is not available / speed is low
      } else {
        // TODO hide marker
        this.marker.setPosition(undefined);
      }
    }
  },
  methods: {
    autoCenterMap(loc) {
      if (this.autoCenter) {
        if (this.autoCenterMode === 'point' && loc && loc.position) {
          console.log("Auto-centering on", loc.position)
          this.map.getView().setCenter(loc.position)
          return true
        }
        if (this.autoCenterMode === 'block' && this.active_block) {
          let block_feature = this.blocks[this.active_block]
          if (block_feature) {
            console.log("Auto-centering on", this.active_block)
            this.map.getView().fit(block_feature.getGeometry(), {padding: Array(4).fill(5)})
            return true
          } else {
            console.log("No block", this.active_block, this.blocks)
          }
          return false
        }
        if (this.autoCenterMode === 'row' && this.active_block && this.active_row) {
          let row_feature = this.rows[this.active_block + "!!" + this.active_row]
          if (row_feature) {
            console.log("Auto-centering on", this.active_block, this.active_row)
            this.map.getView().fit(row_feature.getGeometry(), {padding: Array(4).fill(5)})
            return true
          } else {
            console.log("No row", this.active_block, this.active_row, this.rows)
          }
          return false
        }
        console.log("Can't auto center", this.autoCenterMode, loc, this.active_block, this.active_row)
        return false
      } else {
        console.log("Auto center disabled", this.autoCenter, this.autoCenterMode, loc, this.active_block, this.active_row)
      }
    },
    refreshColors() {
      return this.orchardSource.changed()
    },
    drawOnCanvas(canvas, include_basemap=false) {
      let map = this.map;
      let component = this
      return new Promise((resolve, reject) => {
        let size = map.getSize();
        let viewResolution = map.getView().getResolution();

        if (!include_basemap) {
          for (let layer of this.basemapLayers) {
            map.removeLayer(layer)
          }
        }
        map.once('rendercomplete', function () {
          try {
            console.debug("map @rendercomplete")
            const context2d = canvas.getContext('2d');
            context2d.fillStyle = "white"
            context2d.fillRect(0,0, canvas.width, canvas.height);
            map.getTargetElement().querySelectorAll('.ol-layer canvas').forEach((mapCanvas) => {
                  if (mapCanvas.width > 0) {
                    const opacity = mapCanvas.parentNode.style.opacity;
                    context2d.globalAlpha = opacity === '' ? 1 : Number(opacity);
                    const transform = mapCanvas.style.transform;
                    // Get the transform parameters from the style's transform matrix
                    const matrix = transform.match(/^matrix\(([^(]*)\)$/)[1].split(',').map(Number);
                    // Apply the transform to the export map context
                    context2d.setTransform(matrix)
                    console.debug("Drawing ...")
                    context2d.drawImage(mapCanvas, 0, 0);
                  }
                }
            );
            console.debug("Finished drawing.")
            context2d.globalAlpha = 1;
            context2d.setTransform(1, 0, 0, 1, 0, 0);
            resolve(canvas)
          } catch (e) {
            reject(e)
          } finally {
            // Reset original map size
            let layers = map.getLayers()
            for (let layer of component.basemapLayers) {
              if (layers.getArray().indexOf(layer) < 0) {
                layers.insertAt(0, layer)
              }
            }
            map.setSize(size);
            map.getView().setResolution(viewResolution);
          }
        });
        // Set export size
        map.setSize([canvas.width, canvas.height]);
        const scaling = Math.min(canvas.width / size[0], canvas.height / size[1]);
        map.getView().setResolution(viewResolution / scaling);
      })
    },
    getFeaturesAtCoordinate(coordinate, viewProj = false) {
      return this.orchardSource.getFeaturesAtCoordinate(coordinate, viewProj)
    },
    loadOrchardData(extent, resolution, projection, success, failure) {
      console.debug("Loading orchard data for ", this.company, this.kpin, extent, resolution, projection)
      this.loadMap(this.orchardSource, this.company, this.kpin, resolution, projection, success, failure, true)
    },
    loadOrchardsOverview(extent, resolution, projection, success, failure) {
      console.debug("Loading overview data for ", this.companies, extent, resolution, projection)
      for (let company of this.companies) {
        this.loadMap(this.overviewSource, company, "overview", resolution, projection, success, failure, false)
      }
    },
    hashCode(value) {
      let hash = 0;
      if (value.length === 0) return hash;
      for (let i = 0; i < value.length; i++) {
        let chr = value.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    },
    loadMap(source, company, kpin, resolution, projection, success, failure, auto_fit) {
      if (company && kpin) {
        loadOrchardMap(company, kpin).then(value => {
          console.debug("Loaded map", company, kpin, !!value)
          const features = new OSMXML().readFeatures(value);
          console.debug("Parsed features", features.length)
          const id_base = this.hashCode(company + "!" + kpin) * 100000
          for (const feature of features) {
            feature.setId(id_base + feature.getId())
          }
          source.addFeatures(features)
          if (auto_fit) {
            for (const feature of features) {
              if (feature.get('acuris') === "orchard") {
                this.map.getView().fit(feature.getGeometry(), {padding: Array(4).fill(50)});
              }
            }
          }
          let blocks_index = {}
          let rows_index = {}
          for (const feature of features) {
            if (feature.get('acuris') === "block") {
              blocks_index[feature.get('name')] = feature
            }
            if (feature.get('acuris') === "row") {
              rows_index[feature.get('location:block') + "!!" + feature.get('name')] = feature
            }
          }
          // console.log("Parsed blocks", Object.keys(blocks_index))
          // console.log("Parsed rows", Object.keys(rows_index))
          this.blocks = blocks_index
          this.rows = rows_index
          success(features)
        }).catch(e => {
          console.error("Failed to load map of", company, kpin, e);
          // source.removeLoadedExtent(source.extent)
          failure();
        })
      } else {
        success([])
      }
    },
    mapFeatureOrchardLocation(feature) {
      let orchardLocation = {}
      for (let prop in feature.values_) {
        if (prop.startsWith('location:')) {
          orchardLocation[prop.slice(9)] = feature.values_[prop]
        }
      }
      return orchardLocation
    },
    selectedObjectStyle(feature, resolution) {
      return this.objectStyleInternal(feature, resolution, true, false)
    },
    objectStyle(feature, resolution) {
      return this.objectStyleInternal(feature, resolution, false, false)
    },
    overviewObjectStyle(feature, resolution) {
      return this.objectStyleInternal(feature, resolution, false, true)
    },
    objectStyleInternal(feature, resolution, selected, overview) {
      const extent = feature.getGeometry().getExtent();

      const pointResolution = getPointResolution(this.map.getView().getProjection(), resolution, extent);
      let acuris_feature = feature.get('acuris')
      if (acuris_feature) {
        return this.acurisStyle(acuris_feature, feature, pointResolution, selected, overview)
      } else {
        return this.otherStyle(feature, pointResolution, overview)
      }
    },
    acurisStyle(kind, feature, resolution, selected, overview) {
      if (overview && feature.get('kpin') === this.kpin) {
        return null
      }
      switch (kind) {
        case "bay": {
          let orchardLocation = this.mapFeatureOrchardLocation(feature)
          let color = this.bayColor(orchardLocation)
          if ((color || resolution < 0.5) && resolution < 10) {
            return new Style({
              zIndex: selected ? 200 : 100,
              fill: new Fill({
                color: color || 'rgba(127, 127, 127, 0.5)'
              }),
              stroke: selected ? new Stroke({color: 'rgb(255, 0, 0)'}) : resolution < 0.5 ? new Stroke({color: `rgba(255,255,255,${1 - resolution / 0.5})`}) : undefined,
              // text: resolution < 0.5 ? this.createTextStyle(resolution.toFixed(2), resolution * 3) : undefined,
            })
          } else {
            return null
          }
        }
        case "row": {
          let active = !overview && this.active_block === feature.get('location:block') && this.active_row === feature.get('name')
          let num = parseInt(feature.get('name'))
          const even = num % 2 === 0
          return new Style({
            zIndex: 101,
            stroke: resolution < 0.5 || active ? new Stroke({
              color: active ? 'rgba(255,97,0,1)' : 'rgba(255, 255 , 255, 1)',
              width: active ? 3 : undefined,
              lineDash: active ? [5/resolution, 5/resolution] : undefined
            }) : undefined,
            fill: new Fill({
              color: process.env.VUE_APP_LOCAL === undefined ? 'rgba(255,255,255,0.01)' : (even ? 'rgba(255,0,255,0.2)' : 'rgba(255,255,0,0.2)'),
            })
          })
        }
        case "block": {
          let active = !overview && this.active_block === feature.get('name')
          return new Style({
            zIndex: 102,
            stroke: new Stroke({
              color: active ? 'rgba(255,97,0,1)' : 'rgba(255, 255 , 255, 1)',
              lineDash: overview ? [5/resolution, 5/resolution] : undefined,
              lineDashOffset: 0,
              width: active ? 3 : undefined
            }),
            fill: new Fill({
              color: 'rgba(255, 255 , 255, 0.01)',
            }),
            text: this.createTextStyle(feature.get('name'), resolution / 2, 'point', overview ? 'grey': 'black'),
          })
        }
        case "row-center": {
          let name_int = parseInt(feature.get('name'))
          let res_dvblity = Math.round(20 * (resolution - 0.3))
          let name_dvbl = res_dvblity > 1 && name_int !== 1 ? (name_int % res_dvblity === 0) : true
          let name_visible = name_dvbl && (resolution <= 0.6)
          return new Style({
            zIndex: 105,
            text: name_visible ? this.createTextStyle(feature.get('name'), resolution * 2, 'point') : undefined,
          })
        }
        case "orchard": {
          if (resolution > 6) {
            return new Style({
              zIndex: 104,
              text: this.createTextStyle(feature.get('name'), 1, 'point', overview ? 'white': 'black')
            })
          } else {
            return new Style({
              zIndex: 104,
              fill: new Fill({
                color: 'rgba(1, 1, 1, 0.01)',
              }),
              stroke: new Stroke({
                color: 'rgb(157, 157, 157)',
                width: 3,
                lineDash: overview ? [5 / resolution, 5 / resolution] : undefined
              }),
              text: resolution >= 1.6 ? this.createTextStyle(feature.get('name'), resolution / 3, 'point', overview ? 'grey': 'black') : undefined,
            })
          }
        }
        case "section":
          return null;
        default:
          console.log("Unhandled 'acuris' feature type", kind)
      }
    },
    otherStyle(feature, resolution) {
      if (resolution > 3) {
        // console.debug("Resolution", resolution)
        return null
      }
      let power_feature = feature.get('power')
      if (power_feature !== undefined) {
        if (power_feature === "line") {
          return new Style({
            zIndex: 200,
            stroke: new Stroke({
              color: 'black',
              width: 2,
              lineDash: [25, 10, 5, 10, 25, 10]
            })
          });
        }
      }
      let amenity_feature = feature.get('amenity')
      if (amenity_feature !== undefined) {
        // toilets, wind_machine, parking, shelter (natural, no subtype)
        if (amenity_feature === "shelter") {
          let shelter_type_feature = feature.get('shelter_type')
          if (shelter_type_feature !== undefined) {
            if (shelter_type_feature === "natural") {
              return new Style({
                zIndex: 200,
                stroke: new Stroke({
                  color: '#015e00',
                  width: 2 / resolution
                }),
              });
            }
          }
          return new Style({
            zIndex: 200,
            stroke: new Stroke({
              color: '#ffffff',
              width: 0.85
            }),
          });
        } else if (amenity_feature === "parking") {
          return new Style({
            zIndex: 205,
            image: new Icon({
              src: '/static/img/icons/Parking-16.svg'
            })
          });
        } else if (amenity_feature === "toilets") {
          return new Style({
            zIndex: 205,
            image: new Icon({
              src: '/static/img/icons/Toilets-16.svg'
            })
          });
        }
      }
      let barrier_feature = feature.get('barrier')
      if (barrier_feature !== undefined) {
        if (barrier_feature === "gate") {
          return new Style({
            zIndex: 200,
            fill: new Fill({
              color: 'rgba(1,1,1,0.01)',
            }),
            stroke: new Stroke({
              color: 'red',
              width: 0.5
            }),
          });
        } else {
          return new Style({
            zIndex: 100,
            stroke: new Stroke({
              color: '#b4b4b4',
              width: 2
            }),
          });
        }
      }
      let building_type = feature.get('building')
      if (building_type !== undefined) {
        return new Style({
          zIndex: 100,
          stroke: new Stroke({
            color: '#b4b4b4',
            width: 1
          }),
          fill: new Fill({
            color: 'rgba(145,145,145,0.53)',
          }),
        });
      }
      return null
    },
    createTextStyle(text, resolution, placement = 'point', color = 'black') {
      let align = 'center';
      let baseline = 'middle';
      let size = '10px';
      let height = '1';
      let offsetX = 0;
      let offsetY = 0;
      let weight = 'bold';

      let maxAngle = 0.7853981633974483;
      let overflow = true;
      let rotation = 0.0;
      let font = weight + ' ' + size + '/' + height + ' ' + 'Verdana';
      let fillColor = color;
      // let fillColor = 'black';

      return new Text({
        textAlign: align === '' ? undefined : align,
        textBaseline: baseline,
        font: font,
        text: text,
        fill: new Fill({color: fillColor}),

        offsetX: offsetX,
        offsetY: offsetY,
        placement: placement,
        maxAngle: maxAngle,
        overflow: overflow,
        rotation: rotation,
        scale: [1 / resolution, 1 / resolution]
      });
    },
  },
  mounted() {
    // this is where we create the OpenLayers map
    useGeographic()
    const component = this
    this.orchardSource = new VectorSource({
      attributions: "Orchard: <a href=\"https://acurissystems.com\"> Acuris Systems</a>",
      loader: this.loadOrchardData,
      strategy: all
    })
    this.overviewSource = new VectorSource({
      loader: this.loadOrchardsOverview,
      strategy: all
    })
    this.basemapLayers = [
      new TileLayer({
      source: new XYZ({
        attributions: "BoP 2024 Draft Basemap: <a href=\"https://basemaps.linz.govt.nz\">&copy; CC BY 4.0 LINZ</a>",
        url: `https://basemaps.linz.govt.nz/v1/tiles/bay-of-plenty-2024-0.2m/WebMercatorQuad/{z}/{x}/{y}.webp?api=${mapApi['key']}`
      })
    }),
      new TileLayer({
      source: new XYZ({
        attributions: "Tauranga 2022 Winter Basemap: <a href=\"https://basemaps.linz.govt.nz\">&copy; CC BY 4.0 LINZ</a>",
        url: `https://basemaps.linz.govt.nz/v1/tiles/tauranga-winter-2022-0.1m/WebMercatorQuad/{z}/{x}/{y}.webp?api=${mapApi['key']}`
      })
    }),
      new TileLayer({
      source: new XYZ({
        attributions: "BoP 2023-2024 Basemap: <a href=\"https://basemaps.linz.govt.nz\">&copy; CC BY 4.0 LINZ</a>",
        url: `https://basemaps.linz.govt.nz/v1/tiles/bay-of-plenty-2023-2024-0.2m/WebMercatorQuad/{z}/{x}/{y}.webp?api=${mapApi['key']}`
      })
    }),
      new TileLayer({
        source: new XYZ({
          attributions: "Basemap: <a href=\"https://basemaps.linz.govt.nz\">&copy; CC BY 4.0 LINZ</a>",
          url: `https://basemaps.linz.govt.nz/v1/tiles/aerial/EPSG:3857/{z}/{x}/{y}.jpg?api=${mapApi['key']}`
        })
      })
    ]

    let map_view = new View({
      zoom: 6,
      center: [171.493133, -40.344153],
    })

    const autogeolocation = new Geolocation({
      // enableHighAccuracy must be set to true to have the heading value.
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: 'EPSG:4326',
    });

    // el('track').addEventListener('change', function () {
    //   geolocation.setTracking(this.checked);
    // });

// handle geolocation error.
    autogeolocation.on('error', function (error) {
      autogeolocation.setTracking(false)
      console.error("Geolocation error", error)
    });

    const accuracyFeature = new Feature();
    autogeolocation.on('change:accuracyGeometry', function () {
      console.log("Accuracy", autogeolocation.getAccuracyGeometry())
      accuracyFeature.setGeometry(autogeolocation.getAccuracyGeometry());
    });

    const positionFeature = new Feature();
    positionFeature.setStyle(
      new Style({
        image: new CircleStyle({
          radius: 6,
          fill: new Fill({
            color: '#3399CC',
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 2,
          }),
        }),
      }),
    );
    const self = this
    autogeolocation.on('change:position', function () {
      const coordinates = autogeolocation.getPosition();
      console.log("Updated Position", coordinates, "was", self.geolocation)

      if (coordinates) {
        positionFeature.setGeometry(new Point(coordinates));
        map_view.fit(new Point(coordinates), {maxZoom: 17})
        console.log("Current KPIN: ", !self.kpin, !self.kpin, "Overview Source", self.overviewSource, !!self.overviewSource)
        if (!self.kpin && self.overviewSource != null) {
          console.log("Auto-selecting orchard")
          self.overviewSource.forEachFeatureAtCoordinateDirect(coordinates, (feature) => {
            if (feature.get('acuris') === 'orchard') {
              const located_kpin = feature.get('kpin')
              console.log("Found orchard", located_kpin)
              for (let orchard of self.$root.orchards) {
                if (orchard.kpin === located_kpin) {
                  self.$root.orchard = orchard
                  console.log("Selected orchard", orchard)
                  return true
                }
              }
            }
          })
        }
        // self.geolocation = {position: coordinates}
      } else {
        positionFeature.setGeometry(null);
        // self.geolocation = null
      }
    });

    autogeolocation.on('change:tracking', function () {
      if (autogeolocation.getTracking()) {
        console.log("Tracking enabled")
        const coordinates = autogeolocation.getPosition();
        console.log("New Position", coordinates, autogeolocation.getProjection())
        positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
      } else {
        console.log("Tracking disabled")
        positionFeature.setGeometry(null);
        accuracyFeature.setGeometry(null);
      }
    });

    this.map = new Map({
      target: this.$refs.map_root,
      layers: this.basemapLayers.reverse().concat([
        new VectorLayer({
          source: this.overviewSource,
          style: this.overviewObjectStyle,
          declutter: true,
        }),
        new VectorLayer({
          source: this.orchardSource,
          style: this.objectStyle,
          updateWhileAnimating: false,
          updateWhileInteracting: false,
          renderOrder: null
        }),
        new VectorLayer({
          source: new VectorSource({
            features: [accuracyFeature, positionFeature],
          }),
        })
      ]),

      // the map view will initially show NZ
      view: map_view,
      controls: controlDefaults({
            attributionOptions: {collapsible: true},
            zoomOptions: {delta: 0.5}
          }
      ).extend([
        new ScaleLine({bar: true})
      ]).extend(process.env.VUE_APP_LOCAL === undefined ?[
        new LocateControl({geolocation: autogeolocation})
      ] : [])
    })
    this.map.on('pointermove', (event) => {
      let features = this.map.getFeaturesAtPixel(event.pixel);
      this.$emit('update:hovered', features)
    })

    const select = new Select({
      filter(f) {
        return f.get('acuris') === 'bay'
      },
      style: this.selectedObjectStyle,
      toggleCondition: never
    })

    select.on('select', function (event) {
      console.debug("Selected", event.selected)
      component.$emit('update:selected', event.selected)
    })
    this.map.addInteraction(select)

    this.marker = new Overlay({
      positioning: 'center-center',
      element: this.$refs.geolocation_marker,
      stopEvent: false,
      autoPan: false,
    });
    this.map.addOverlay(this.marker);
  },
}
</script>

<template>
  <div>
    <div ref="map_root" style="width: 100%; height: 100vh"/>
    <img src="/img/icons/geolocation_marker.png" alt="Current Location" ref="geolocation_marker" />
    <div class="prompt block-prompt" v-if="!company || !kpin">
      <h4>No Orchard Selected.</h4>
      <span>Choose an orchard and a scan from the menus at the top.</span>
    </div>
  </div>
</template>

<style>
.tracking svg {
  fill: deepskyblue;
}
.locate-me {
  bottom: 80px;
  right: .5em;
}
.ol-touch .locate-me {
  top: 65px;
  left: .5em;
  bottom: unset;
  right: unset;
}
</style>

<style scoped>

.prompt {
  position: absolute;
  text-align: center;

  background-color: lightgray;
  border-radius: 0.5em;
  padding: 0.5rem 1rem 1rem 1rem;
}

.prompt h4 {
  margin-top: 16px;
}

.prompt.block-prompt {
  top: calc(50% - 4rem);
  /* right: calc(50% - 17rem); */
  right: calc(50% - 15rem);
}


@media (max-aspect-ratio: 1/1) {
/* @media (max-aspect-ratio: 8/5) and (min-aspect-ratio: 1/1) { */
  .prompt {
    position: absolute;
    text-align: center;

    background-color: lightgray;
    border-radius: 0.5em;
    padding: 0.5rem 1rem 1rem 1rem;
  }

  .prompt h4 {
    margin-top: 12px;
  }
  .prompt.block-prompt {
    /* top: calc(50% - 2rem); */
    right: calc(50% - 9rem);
    max-width: 18rem;
  }
}
</style>
